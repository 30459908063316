<template>
  <div class="popup-holder">
    <PopupContent
      ref="lampstatus"
      :x="location[0]"
      :y="location[1]"
      :assetId="assetId"
      :address="address"
      :status="status"
      :objectId="objectId"
      :globalId="globalId"
      :owner="owner"
      :ean="ean"
      :view="view"
      :sla="sla"
    />
  </div>
</template>
<script lang="js">
import Vue from "vue";
import PopupContent from "../components/PopupContent.vue";
import MapView from "@arcgis/core/views/MapView";
import * as _ from "underscore";
import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import { Config } from "@/config";
import { SpatialReference, Extent } from "@arcgis/core/geometry";
import { MELDING_STATUS } from "../enums/melding-status";
import { eventBus } from "../event-bus";
import { isServiceUser, isSlaUser } from '../utils';
/* eslint-disable */ 
export default Vue.extend({
  name: "PopupHolder",
  components: { PopupContent },
  props: {
    view: {
      type: MapView,
      required: true,
    },
    scale: {
      type: Number,
    },
  },
  data: function () {
    return {
      assetId: 0,
      status: null,
      location: [],
      address: null,
      firstLoad: true, // HACK due to loading of popup. At the first load the popup visiblity is changing to visbile=false, so at first load it has to be checked
      objectId: null,
      globalId: null,
      owner: '',
      ean: '',
      sla: '',
    };
  },
  methods: {
      /**
     * Function to create an extent around a given geometry with a specified expansion value.
     * @param {Object} geometry - The geometry around which to create the extent.
     * @param {Number} expandBy - The value by which to expand the extent.
     * @return {Extent} - The created extent.
     */
    getExpandExtent(geometry, expandBy){
      return new Extent({
            xmin: geometry.x - expandBy,
            xmax: geometry.x + expandBy,
            ymin: geometry.y - expandBy,
            ymax: geometry.y + expandBy,
            spatialReference: { wkid: 25833 },
          });
    },

    /**
     * Function to query a layer based on given geometry, expansion value, statuses, and status field.
     * @param {String} layerId - The ID of the layer to query.
     * @param {Object} geometry - The geometry to use in the query.
     * @param {Number} expandBy - The value by which to expand the query extent.
     * @param {Array} statuses - The list of statuses to filter the query.
     * @param {String} statusField - The field name representing the status in the layer.
     * @return {Promise<Array>} - A promise that resolves with the filtered features.
     */

    queryLayer(layerId, geometry, expandBy, statuses, statusField){
      let layer = this.view.map.findLayerById(layerId);
      let query = layer.createQuery();
      query.geometry = this.getExpandExtent(geometry, expandBy);
      query.spatialRelationship = "intersects";
      query.returnGeometry = true;
      query.where = `${statusField} IN (${statuses.map(status => `'${status}'`).join(",")})`;
      query.outFields = [statusField, "OBJECTID"];

      return layer.queryFeatures(query).then((response) => {
      return response.features.filter((feature) => {
        return (
          feature.geometry.x >= geometry.x - expandBy &&
          feature.geometry.x <= geometry.x + expandBy &&
          feature.geometry.y >= geometry.y - expandBy &&
          feature.geometry.y <= geometry.y + expandBy
        )
      }) 
      })
    },

     /**
     * Function to get the point state of the armature layer based on a reference armature.
     * @param {Object} refArmatur - The reference armature to use in the query.
     */
     getArmatureLayerPointState(refArmatur) {
          const expandBy = Config.ext.notificationToleranceHit;
          const inPlanStatuses = [MELDING_STATUS.PLANNED];
          
          this.queryLayer("lamps-layer", refArmatur.geometry, expandBy, inPlanStatuses, "PLANSTATUS").then((armaturNotifications) => {
            if (armaturNotifications.length > 1) {
              let notificationsOids = armaturNotifications.map((n) => n.attributes["OBJECTID"]);
              console.error(
                `There are more notifications on armatur OID: ${refArmatur.attributes.OBJECTID} (x,y)=>(${refArmatur.geometry.x},${refArmatur.geometry.y}). Notifications: ${notificationsOids}. The first one returned will be taken.`
              );
            }

            // If the given attribute is present, then it assign its value. Else it assign null which is equal to normal status
            this.status = armaturNotifications.length !== 0 
              ? armaturNotifications.sort((a, b) => a.attributes.OBJECTID - b.attributes.OBJECTID)[0].attributes["PLANSTATUS"]
              : null;
          });
        },

     /**
     * Function to show a popup for a reference armature with relevant details.
     * @param {Object} refArmatur - The reference armature for which to show the popup.
     */
    showPopup(refArmatur) {

      this.status = undefined;
      this.address = null;
      this.location = [];
      this.assetId = null;
      this.objectId = null;
      this.globalId = null;
      this.owner = null;
      this.ean = null;
      this.sla = null;

      const expandBy = Config.ext.notificationToleranceHit;
      const inStatuses = [
        MELDING_STATUS.REGISTERED,
        MELDING_STATUS.JOB_CREATED_NOT_DELEGATED,
        MELDING_STATUS.JOB_ONGOING,
        MELDING_STATUS.JOB_ON_HOLD,
        MELDING_STATUS.JOB_ASSIGNED,
      ];
      
      this.queryLayer("notification-layer", refArmatur.geometry, expandBy, inStatuses, "STATUS").then((armaturNotifications) => {
        if (armaturNotifications.length > 1) {
          let notificationsOids = armaturNotifications.map((n) => n.attributes["OBJECTID"]);
          console.error(
            `There are more notifications on armatur OID: ${refArmatur.attributes.OBJECTID} (x,y)=>(${refArmatur.geometry.x},${refArmatur.geometry.y}). Notifications: ${notificationsOids}. The first one returned will be taken.`
          );
        }

        if (armaturNotifications.length !== 0) {
          this.status = armaturNotifications.sort((a, b) => a.attributes.OBJECTID - b.attributes.OBJECTID)[0].attributes["STATUS"];
        } else {
          this.getArmatureLayerPointState(refArmatur);
        }

        let srcAddress = refArmatur.attributes["VED_ADRESSE"];
        let address = srcAddress ? srcAddress.split("Avstand")[0].trim() : null;

        this.address = address;
        this.location = [refArmatur.geometry.x, refArmatur.geometry.y];
        this.assetId = refArmatur.attributes["ASSETID"];
        this.objectId = refArmatur.attributes["OBJECTID"];
        this.globalId = refArmatur.attributes["GLOBALID"];
        this.owner = refArmatur.attributes["KUNDE"];
        this.ean = refArmatur.attributes["EAN_NR"];
        this.sla = refArmatur.attributes["SLA_KRAV"];
      });
    },

    /**
     * Function to return Name of Armatur Layer for the filtering in graphics element
     * @return {String} - string of current Armatur layer name due to the URL mode
     */
    getArmaturLayerName(){
      return (isServiceUser()?"owners-layer":
                                            isSlaUser()?"slas-layer": 
                                                                    "lamps-layer");
    },
  },
  created: function () {
    eventBus.$on("foundNearestLamp", (graphics) => {
      this.showPopup(graphics);
    });
    eventBus.$on("faultFormChanged", (faultForm) => {
      if (faultForm === null) {
        this.view.popup.close();
      }
    });
    this.view.popup.close();
    this.view.popup = {
      autoOpenEnabled: false,
      dockOptions: {
        buttonEnabled: false,
      },
      collapseEnabled: false,
      actions: [],
      visibleElements: {
        closeButton: false,
      },
    };
    //TODO control popup visiblity only with location. In genergal, control popup visibility in one way. Now it is controlled (opened, closed) with map view or with popup holder conpoment.
    this.view.popup.watch("visible", (visible) => {
      eventBus.$emit("popupVisible", visible);
      if (this.firstLoad) {
        this.firstLoad = false;
        return;
      } // HACK check property define
      if (visible === false) {
        this.address = null;
        this.location = [];
        this.assetId = 0;
      }
    });
    this.view.on("click", (event) => {
      this.view.popup.fetchFeatures(event.screenPoint).then((response) => {
        response.allGraphicsPromise.then((graphics) => {

          if (graphics.length === 0) {
            this.address = null;
            this.location = [];
            this.assetId = 0;
            return;
          }

          /*
          let armaturs = (!isServiceUser()) 
                            ? graphics.filter((g) => g.layer.id === "lamps-layer")
                            : graphics.filter((g) => g.layer.id === "owners-layer");
                            */
          let armaturs = graphics.filter((g) => g.layer.id === this.getArmaturLayerName());              
          let groupedByCoord = _.groupBy(armaturs, function (g) {
            return `${g.geometry.x} ${g.geometry.y}`;
          });
          if (Object.keys(groupedByCoord).length > 1) {
            if (this.view.zoom !== Config.ext.mapconfig.maxZoom) {
              this.$buefy.toast.open({
                duration: 5000,
                message: this.$t("zoomInToReportFault"),
                position: "is-bottom",
                type: "is-danger",
              });
              return;
            }
            console.warn(
              `There are more armaturs ${
                Object.keys(groupedByCoord).length
              } on one click, taking the first one.`
            );
          }
          let selectedArmatur = groupedByCoord[Object.keys(groupedByCoord)[0]];
          if(isSlaUser){
            if (selectedArmatur.length > 1) {
            console.warn(
              `On that aramtur there are more lamps (bulbs) (${selectedArmatur.length}) on one place. Taking the one with LOWEST SLA_KRAV`
            );
          } // there are more lamps (bulbs) on one place, so take the lamp with the lowest ASSETID because
          let refArmatur = selectedArmatur.sort((a, b) =>
            a.getAttribute("SLA_KRAV") > b.getAttribute("SLA_KRAV") ? 1 : -1
          )[0]; //--------------- // check notifications on armatur
          this.showPopup(refArmatur);
          }

          if(!isSlaUser){
            if (selectedArmatur.length > 1) {
            console.warn(
              `On that aramtur there are more lamps (bulbs) (${selectedArmatur.length}) on one place. Taking the one with LOWEST ASSETID`
            );
          } // there are more lamps (bulbs) on one place, so take the lamp with the lowest ASSETID because
          let refArmatur = selectedArmatur.sort((a, b) =>
            a.getAttribute("ASSETID") > b.getAttribute("ASSETID") ? 1 : -1
          )[0]; //--------------- // check notifications on armatur
          this.showPopup(refArmatur);
          }
        });
      });
    });
  },
  watch: {
    location: function (newVal) {
      let me = this;
      if (newVal && newVal[0] && newVal[1]) {
        this.view.popup.open({
          location: new Point({
            x: newVal[0],
            y: newVal[1],
            spatialReference: new SpatialReference({ wkid: 25833 }),
          }), // event.mapPoint,
        content: this.$refs.lampstatus.$el, //htmlElement
        title: "", // title text 'Search result' keeps also on search result popup, so it needs to be explicitly removed, since title is created by html content
        includeDefaultActions: false
        });
        let marker = {
          type: "simple-marker",
          outline: { cap: "square", width: 3, color: [255, 255, 255, 1] },
          size: 16,
          color: [255, 255, 255, 0],
        };
        let pointHighlight = new Graphic({
          geometry: new Point({
            x: newVal[0],
            y: newVal[1],
            spatialReference: { wkid: 25833 },
          }),
          symbol: marker,
        });
        this.view.graphics.removeAll();
        this.view.graphics.add(pointHighlight);
      } else {
        eventBus.$emit("faultFormChanged", null);
        this.view.graphics.removeAll();
        me.view.popup.close();
      }
    },
  },
});
</script>
<style scoped></style>
